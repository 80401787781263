import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Length, MenuItem } from "../../types";
import DropdownMenu from "../DropdownMenu";
import { useLength } from "../../hooks/useLength";

type TProps = {
  selectedLength: MenuItem<Length> | null;
  setSelectedLength: (option: MenuItem<Length>) => void;
  width?: number;
  height?: number;
  placeholder?: string;
  error?: string;
  showIcon?: boolean;
};

const LengthDropdown = ({
  selectedLength,
  setSelectedLength,
  ...rest
}: TProps) => {
  const { lengths, error, getLengths } = useLength();

  const lengthOptions = useMemo(() => {
    return lengths.map((l) => {
      const item = {
        label: `${l.value}`,
        value: l,
        onClick: () => setSelectedLength(item),
      };
      return item;
    });
  }, [lengths, setSelectedLength]);

  useEffect(() => {
    getLengths();
  }, [getLengths]);

  useEffect(() => {
    if (error) {
      toast(error.error, {
        toastId: "fetch-lengths-error",
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });
    }
  }, [error]);

  return (
    <DropdownMenu<Length>
      items={lengthOptions}
      selectedOption={selectedLength}
      setSelectedOption={setSelectedLength}
      {...rest}
    />
  );
};

export default LengthDropdown;
