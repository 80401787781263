import {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { fetchLengths } from '../api/length';
import { Length } from '../types';

interface LengthContextType {
  lengths: Length[];
  error: any;
  getLengths: () => void;
}

export const LengthContext = createContext<LengthContextType>({
  error: null,
  lengths: [],
  getLengths: () => undefined,
});

export const LengthProvider = ({ children }: { children: ReactNode }) => {
  const [lengths, setLengths] = useState<Length[]>([]);
  const [error, setError] = useState<any>(null);

  const getLengths = useCallback(async () => {
    const result = await fetchLengths();
    if (result.error) {
      setError(result.error);
    } else {
      setLengths(result.data ?? []);
    }
  }, []);

  const value = useMemo(
    () => ({
      lengths,
      error,
      getLengths,
    }),
    [lengths, error, getLengths]
  );

  return (
    <LengthContext.Provider value={value}>{children}</LengthContext.Provider>
  );
};
