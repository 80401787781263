import {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { fetchWidths } from '../api/width';
import { Width } from '../types';

interface WidthContextType {
  widths: Width[];
  error: any;
  getWidths: () => void;
}

export const WidthContext = createContext<WidthContextType | undefined>({
  widths: [],
  error: null,
  getWidths: () => undefined,
});

export const WidthProvider = ({ children }: { children: ReactNode }) => {
  const [widths, setWidths] = useState<Width[]>([]);
  const [error, setError] = useState<any>(null);

  const getWidths = useCallback(async () => {
    const result = await fetchWidths();
    if (result.error) {
      setError(result.error);
    } else {
      setWidths(result.data ?? []);
    }
  }, []);

  const value = useMemo(
    () => ({
      widths,
      error,
      getWidths,
    }),
    [error, widths, getWidths]
  );

  return (
    <WidthContext.Provider value={value}>{children}</WidthContext.Provider>
  );
};
